import PropTypes from "prop-types";
import SendBirdBot from "./SendBirdBot";

function OpenChat({ sendBirdUser }) {
  return (
    <>
      <SendBirdBot sendBirdUser={sendBirdUser} />
    </>
  );
}

OpenChat.propTypes = {
  sendBirdUser: PropTypes.any.isRequired,
};

export default OpenChat;
