import { useContext, useEffect, useState } from "react";
import { CustomerUserContext } from "App";
import axios from "axios";

import OpenChat from "./OpenChat";
// import OpenSidebar from "../../layouts/pages/chat_v2/OpenSidebar";

function SendBird() {
  const [customerUser] = useContext(CustomerUserContext);

  const [sendBirdUser, setSendBirdUSer] = useState(null);

  const getSendbirdUser = () => {
    axios
      .post(`/api/get-customer/${customerUser?.customer.created_by_user_id}`)
      .then((response) => {
        setSendBirdUSer(response?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getSendbirdUser();
  }, []);

  return <>{sendBirdUser && <OpenChat sendBirdUser={sendBirdUser} />}</>;
}

// Typechecking props for

export default SendBird;
