import React, { useEffect, useState } from "react";
// import "@sendbird/uikit-react/dist/index.css";
import { ChatAiWidget } from "@sendbird/chat-ai-widget";
import "@sendbird/chat-ai-widget/dist/style.css";
import PropTypes from "prop-types";

const APP_ID = process.env.REACT_APP_SENDBIRD_APP_ID;
const API_TOKEN = process.env.REACT_APP_SENDBIRD_API_TOKEN;
const BOT_ID = process.env.REACT_APP_SENDBIRD_BOT_Id;

function SendBirdBot({ sendBirdUser }) {
  const USER_ID = sendBirdUser.user_id;

  const issueSessionToken = async (userId, expiryDuration = 10 * 60 * 1000) => {
    const url = `https://api-${APP_ID}.sendbird.com/v3/users/${userId}/token`;
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json; charset=utf8",
        "Api-Token": API_TOKEN,
      },
      body: JSON.stringify({ expires_at: Date.now() + expiryDuration }),
    });

    const data = await response.json();
    if (response.ok) {
      return data.token;
    }

    throw new Error("Failed to issue a session token");
  };

  const configureSession = () => ({
    onSessionTokenRequired: (resolve, reject) => {
      issueSessionToken(USER_ID)
        .then((token) => resolve(token))
        .catch((err) => reject(err));
    },
    onSessionRefreshed: () => {
      // Action to take when session is refreshed
    },
    onSessionError: (err) => {
      // Action to take when session encounters an error
      console.log(err);
    },
    onSessionClosed: () => {
      // Action to take when session is closed
    },
  });

  const [sessionToken, setSessionToken] = useState(null);

  useEffect(() => {
    issueSessionToken(USER_ID).then((token) => setSessionToken(token));
  }, [USER_ID]);

  if (!sessionToken) return null;

  return (
    <ChatAiWidget
      applicationId={APP_ID}
      botId={BOT_ID}
      userId={USER_ID}
      userNickName={sendBirdUser.nickname}
      sessionToken={sessionToken}
      configureSession={configureSession}
      createGroupChannelParams={{ name: sendBirdUser.nickname }}
      botStudioEditProps={{
        welcomeMessages: [
          {
            message: `Hello ${sendBirdUser.nickname}, welcome to our chat! How can I assist you today?`,
            suggestedReplies: ["I am facing issues on appointments", "I want to talk to agent"],
          },
        ],
      }}
    />
  );
}

SendBirdBot.propTypes = {
  sendBirdUser: PropTypes.any.isRequired,
};

export default SendBirdBot;
